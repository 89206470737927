import React, { useEffect, useState } from 'react'
import { Card, Col, Container, Row, Tab, Table, Tabs } from 'react-bootstrap'
import { formatMoney } from '../../utils/formatMoney'
import { ApiClient } from '../../services/api/ApiClient'
import { SessionStatusBadge } from '../patients/Show'
import moment from 'moment'
import { calculateTotal } from '../../forms/Appointment'
export const Billing = () => {
  const [bills, setBills] = useState<any>([])
  const [payments, setPayments] = useState<any>([])
  useEffect(() => {
    ApiClient.appointments.index({ active: false, include: 'billables' }).then((res) => {
      setBills(res)
    })
    ApiClient.payments.index().then((res) => {
      setPayments(res)
    })
  }, [])
  const totalAmount = bills.reduce((acc: number, bill: any) => {
    return acc + bill.billables.reduce((acc2: number, billable: any) => {
      return acc2 + (billable.quantity * parseFloat(billable.price))
    }, 0)
  }, 0)
  const totalCollected = payments.reduce((acc: number, payment: any) => {
    return acc + parseFloat(payment.amount)
  }, 0)
  const allBills = bills.reduce((acc: any, bill: any) => {
    return [...acc, ...bill.billables.map((billable: any) => ({ ...billable, title: bill.title, created_at: bill.start_date }))]
  }, [])
  console.log(allBills)
  return (
    <Container className='mt-3'>
      <Row>
        <Col>
          <Card className='rounded-0 border border-light text-light'>
            <Card.Body>
              <p className='mb-0'>Citas Agendadas</p>
              <p style={{ fontSize: 24 }} className='mb-0'>{bills.length}</p>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card className='rounded-0 border border-success text-success'>
            <Card.Body>
              <p className='mb-0'>Citas Completadas</p>
              <p style={{ fontSize: 24 }} className='mb-0'>{bills.filter((x: any) => x.status === 2).length}</p>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card className='rounded-0 border border-danger text-danger'>
            <Card.Body>
              <p className='mb-0'>Citas Canceladas</p>
              <p style={{ fontSize: 24 }} className='mb-0'>{bills.filter((x: any) => x.status === 1).length}</p>
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card className='rounded-0 border border-primary text-primary'>
            <Card.Body>
              <p className='mb-0'>Facturacion</p>
              {bills && (<p style={{ fontSize: 24 }} className='mb-0'>{formatMoney(totalAmount, { currency: "Bs." })}</p>)}
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card className='rounded-0 border border-warning text-warning'>
            <Card.Body>
              <p className='mb-0'>Cobranzas</p>
              {bills && (<p style={{ fontSize: 24 }} className='mb-0'>{formatMoney(totalCollected, { currency: "Bs." })}</p>)}
            </Card.Body>
          </Card>
        </Col>

      </Row>
      <Row className='mt-3'>
        <Tabs fill defaultActiveKey='appointments'>
          <Tab eventKey="appointments" title="Citas" className='p-3'>
            <Table size='sm' striped>
              <thead>
                <tr>
                  <th>Fecha</th>
                  <th>Paciente</th>
                  <th>Estado</th>

                </tr>
              </thead>
              <tbody>
                {bills.map((bill: any) => (
                  <tr key={bill.id}>
                    <td>{moment(bill.start).format("DD/MM/YYYY")}</td>
                    <td>{bill.title}</td>
                    <td>{<SessionStatusBadge status={bill.status} />}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Tab>
          <Tab eventKey="billing" title="Facturacion" className='p-3'>
            <Table size='sm' striped>
              <thead>
                <tr>
                  <th>#Ref</th>
                  <th>Fecha</th>
                  <th>Paciente</th>
                  <th>Descripcion</th>
                  <th>Precio</th>
                  <th>Subtotal</th>
                </tr>
              </thead>
              <tbody>
                {allBills.map((bill: any) => (
                  <tr key={bill.id}>
                    <td>{bill.id}</td>
                    <td>{moment(bill.created_at).format('DD/MM/YY')}</td>
                    <td>{bill.title}</td>
                    <td> {bill.treatment} ({bill.quantity})</td>
                    <td>{formatMoney(parseInt(bill.price), { currency: "Bs." })}</td>
                    <td>{formatMoney(calculateTotal(bill), { currency: "Bs." })}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Tab>
          <Tab eventKey="payments" title="Cobranzas" className='p-3'>
            <Table size='sm' striped>
              <thead>
                <tr>
                  <th>#Ref</th>
                  <th>Fecha</th>
                  <th>Paciente</th>
                  <th>Forma de Pago</th>
                  <th>Monto Cancelado</th>

                </tr>
              </thead>
              <tbody>
                {payments.map((payment: any) => (
                  <tr key={payment.id}>
                    <td>{payment.id}</td>
                    <td>{moment(payment.created_at).format('DD/MM/YY')}</td>
                    <td> {payment.patient.first_name} {payment.patient.last_name} </td>
                    <td>{payment.payment_method}</td>

                    <td>{formatMoney(parseInt(payment.amount), { currency: "Bs." })}</td>
       
                  </tr>
                ))}
              </tbody>
            </Table>
          </Tab>
        </Tabs>
      </Row>
    </Container>
  )
}